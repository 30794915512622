import React, { useRef, useState } from "react";
import Layout from "../component/Layout";
import {
  Download,
  Ellipsis,
  EllipsisVertical,
  FilePlus,
  Trash2,
  X,
} from "lucide-react";
import { Progress } from "../../../components/ui/progress";
import {
  useDeleteKnowledgeBase,
  useGetStorageUsage,
  useGetUploadKnowledgeBase,
  usePostUploadKnowledgeBase,
} from "../../../query/useEntity";
import { toast } from "sonner";
import { LogoLoading } from "../../../components/loading/loading";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import Skeleton from "react-loading-skeleton";
import { useDarkMode } from "../../../context/DarkModeProvider";
import { dayFormatter } from "../../../utils/formatter";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { Button } from "../../../components/ui/button";
import axios from "axios";
import { DeleteModal } from "../component/modal";
const KnowledgeBase = () => {
  const { darkMode } = useDarkMode();
  const fileRef = useRef(null);
  const [file, setFile] = useState();
  const [isDownloadLoading, setIsDownloadLoading] = useState();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState("");

  const { data: storageUsage, refetch: refetchStorageUsage } =
    useGetStorageUsage();
  const {
    data: knowledgeBase,
    refetch: refetchKnowledgeBase,
    isLoading: knowledgeBaseLoading,
  } = useGetUploadKnowledgeBase();

  const { mutate: uploadKnowledgeBase, isLoading: uploadKnowledgeBaseLoading } =
    usePostUploadKnowledgeBase();

  const { mutate: deleteKnowledgeBase, isLoading: deleteKnowledgeBaseLoading } =
    useDeleteKnowledgeBase();

  const limitFileSize = 100 - storageUsage?.total_storage_used_mb;

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const uploadFile = e.dataTransfer.files[0];
    if (uploadFile.size / (1024 * 1024) < limitFileSize) {
      setFile(uploadFile);

      setError(null); // Clear any previous error
    } else if (uploadFile) {
      setError(`File size must be less than ${limitFileSize} MB.`);
    }
  };

  const handleFileChange = (e) => {
    const uploadFile = e.target.files[0];

    if (uploadFile) {
      if (uploadFile?.size / (1024 * 1024) < limitFileSize) {
        setFile(uploadFile);
        fileRef.current.value = "";
      } else {
        toast.error(
          `File size must be less than ${100 - storageUsage?.total_storage_used_mb} MB.`
        );
        fileRef.current.value = "";
      }
    }
  };

  const handleFileUpload = () => {
    const formData = new FormData();
    formData.append("file", file);
    uploadKnowledgeBase(formData, {
      onSuccess: (res) => {
        toast.success(res.message);
        setFile(null);
        refetchStorageUsage();
        refetchKnowledgeBase();
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleFileDelete = () => {
    deleteKnowledgeBase(deleteId, {
      onSuccess: (res) => {
        toast.success(res.message);
        setDeleteId();
        setIsOpenDeleteModal(false);
        refetchStorageUsage();
        refetchKnowledgeBase();
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleDownload = async (file) => {
    const fileName = file?.file?.split("/")?.[6]?.split("?")?.[0];
    setIsDownloadLoading(file?.id);
    try {
      const response = await axios.get(file?.file, {
        responseType: "blob",
      });

      const downloadUrl = URL.createObjectURL(response?.data);

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsDownloadLoading();

      // Clean up the object URL
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Download failed:", error);
      setIsDownloadLoading();
    }
    setIsDownloadLoading();
  };

  if (uploadKnowledgeBaseLoading) {
    return <LogoLoading />;
  }

  return (
    <Layout title={"Knowledge Base"}>
      <div className="flex flex-col mt-5 w-full dark:bg-darkLightBg rounded-[16px] py-5 sm:py-[31px] min-h-[calc(100vh-320px)] px-5 lg:px-[30.2px]">
        <div className="flex flex-col gap-5 font-pro">
          <p className="font-medium">Upload Files</p>
          <div className="flex flex-col w-full p-6 rounded-[12px] bg-white dark:bg-darkBg h-full shadow-sm max-w-[300px]">
            <p className="font-poppins text-[#888] dark:text-white text-sm">
              <span className="text-black dark:text-white">
                {storageUsage?.total_storage_used_mb || 0}/100 MB
              </span>{" "}
              left to upload files
            </p>
            <Progress
              value={storageUsage?.total_storage_used_mb || 0}
              className="h-1.5 mt-3"
              indicatorColor={"#4f75fa"}
            />
          </div>
          <div className="mx-auto">
            <div
              className={`w-80 h-80 border-4 relative mx-auto ${
                dragActive ? "border-blue-500" : "border-gray-300"
              } border-dashed flex items-center justify-center rounded-lg`}
              onDragEnter={handleDrag}
              onDragOver={handleDrag}
              onDragLeave={handleDrag}
              onDrop={handleDrop}
            >
              {file ? (
                <div className="flex flex-col gap-5">
                  <p className="line-clamp-1">{file?.name}</p>
                  {file && (
                    <Button
                      className="absolute top-2 right-2 w-6 h-6"
                      shape="pill"
                      size="icon"
                      onClick={() => {
                        setFile(null);
                      }}
                    >
                      <X className="w-4 h-4" />
                    </Button>
                  )}
                  <div className="flex items-center gap-3">
                    <button
                      className="bg-newPrimary border border-newPrimary rounded-[7px] text-sm font-medium text-white hover:bg-white dark:hover:bg-darkLightBg hover:text-newPrimary transition-all duration-200 w-[200px] h-10 px-3 font-montserrat"
                      onClick={() => {
                        handleFileUpload();
                      }}
                      // disabled={addSeoLoading}
                    >
                      {"Upload File"}
                    </button>
                  </div>
                </div>
              ) : (
                <label
                  htmlFor="upload"
                  className="flex flex-col items-center justify-center text-center cursor-pointer"
                >
                  <FilePlus className="text-gray-400 mb-1" />
                  <span className="text-gray-400">
                    Drag and drop a file, or click to select
                  </span>
                  <input
                    type="file"
                    id="upload"
                    hidden
                    onChange={handleFileChange}
                  />
                </label>
              )}
            </div>
            {error && <p className="text-red-500 mt-2 text-xs">{error}</p>}
          </div>
        </div>
        {/* <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className="rounded-lg p-10 text-center hover:bg-gray-100 dark:hover:bg-darkBg cursor-pointer mt-10 flex justify-center items-center flex-col"
        >
          <img src="/pngegg.png" className="w-40 h-40 my-5" />
          <h1 className="text-2xl font-medium mb-5">Upload your Files</h1>
          <p className="text-gray-500">
            Drag and drop files here or{" "}
            <label
              htmlFor="fileInput"
              className="text-blue-500 cursor-pointer underline"
            >
              click to upload
            </label>
          </p>
          <input
            type="file"
            multiple
            onChange={handleFileUpload}
            hidden
            id="fileInput"
          />
        </div> */}

        {/* <h1 className="font-medium text-xl mt-5">All Documents</h1>
        <p className="text-gray-500">
          Overview of every files or documnets that you have stored.
        </p> */}
        <div className="mt-10">
          {knowledgeBase?.length <= 0 ? (
            <div className="flex flex-col items-center justify-center w-full h-[50vh] dark:bg-darkLightBg rounded-lg font-pro">
              <img
                src="/illustrations/invoice.svg"
                alt="Questions"
                className="w-[100px] h-[100px] mx-auto mt-10"
              />
              <h1 className="mt-[11px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
                Knowledge Base
              </h1>
              <p className="mt-2 text-[#888888] dark:text-white text-center font-pro">
                Your Knowledge Base will appear here!
              </p>
            </div>
          ) : (
            <div className="rounded-xl overflow-hidden">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Date</TableHead>
                    <TableHead>Name</TableHead>
                    <TableHead>Download</TableHead>
                    <TableHead></TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody className="divide-y">
                  {knowledgeBaseLoading ? (
                    <TableRow>
                      {new Array(4).fill("").map((_, i) => (
                        <TableCell key={i} className="bg-white dark:bg-darkBg">
                          <Skeleton
                            height={30}
                            baseColor={darkMode && "#262626"}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ) : knowledgeBase?.length > 0 ? (
                    knowledgeBase?.map((charge, i) => (
                      <TableRow key={i}>
                        <TableCell className="">
                          {charge?.uploaded_at &&
                            dayFormatter(charge?.uploaded_at)}
                        </TableCell>
                        <TableCell>
                          {charge?.file?.split("/")?.[6]?.split("?")?.[0]}
                        </TableCell>
                        <TableCell>
                          {isDownloadLoading === charge?.id ? (
                            <div className="w-5 h-5 border-t-2 border-b-2 border-[#a6a6a6a] rounded-full animate-spin"></div>
                          ) : (
                            <Download
                              className="text-[#a6a6a6] cursor-pointer"
                              onClick={() => {
                                handleDownload(charge);
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <DropdownMenu>
                            <DropdownMenuTrigger>
                              <Ellipsis className="size-4 text-[#595959]" />
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                              <div className="font-pro text-xs ">
                                <Button
                                  variant="ghost"
                                  className="gap-2 justify-start w-full text-red-500"
                                  onClick={() => {
                                    setIsOpenDeleteModal(true);
                                    setDeleteId(charge?.id);
                                  }}
                                >
                                  <Trash2 className="size-4 " />
                                  Delete
                                </Button>
                              </div>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : null}
                </TableBody>
              </Table>
            </div>
          )}
        </div>

        <DeleteModal
          handleDelete={handleFileDelete}
          openModal={isOpenDeleteModal}
          setOpenModal={setIsOpenDeleteModal}
          title={"Are you sure you want to delete this file?"}
          isLoading={deleteKnowledgeBaseLoading}
        />

        {/* <Table
        className="mt-5 w-full"
        theme={{
          root: {
            base: "w-full text-left text-sm lg:text-base ",
            shadow:
              "absolute left-0 top-0 -z-10 h-full w-full rounded-lg bg-white",
          },
          head: {
            base: "group/head font-pro dark:text-darkGray",
            cell: {
              base: " bg-slate-200 dark:bg-darkBg px-6 py-3 group-first/head:first:rounded-tl-xl group-first/head:last:rounded-tr-xl",
            },
          },
          body: {
            base: "group/body",
            cell: {
              base: "px-6 py-4 group-first/body:group-first/row:first:rounded-tl-xl group-first/body:group-first/row:last:rounded-tr-xl group-last/body:group-last/row:first:rounded-bl-xl group-last/body:group-last/row:last:rounded-br-xl",
            },
          },
        }}
      >
        <Table.Head className="border-b ">
          {tableHead.map((head, index) => (
            <Table.HeadCell key={index}>{head.title}</Table.HeadCell>
          ))}
          <TableHeadCell></TableHeadCell>
        </Table.Head>
        <Table.Body>
          {data.map((file, index) => (
            <Table.Row key={index} className="bg-white dark:bg-darkBg border-b border-t">
              <Table.Cell>
                <div className="flex items-center">
                  <div className="w-5 h-5 bg-gray-200 rounded-md mr-3"></div>
                  <p className="font-medium">{file.name}</p>
                </div>
              </Table.Cell>
              <Table.Cell>{file.date}</Table.Cell>
              <Table.Cell>{file.updated}</Table.Cell>
              <Table.Cell>
                <div className="flex items-center">
                 <img src="/blogpro4.svg" className="w-10 h-10 rounded-full mr-3" />
                  <p className="font-medium">{file.owner}</p>
                </div>
              </Table.Cell>
              <Table.Cell>
                <EllipsisVertical size={20} />
                </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table> */}

        {/* <div className="mt-5">
          <h3 className="text-lg font-semibold">Uploaded Files:</h3>
          <ul>
            {files.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        </div> */}
      </div>
    </Layout>
  );
};

export default KnowledgeBase;
