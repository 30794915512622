/* eslint-disable react/prop-types */
import { Rocket, Upload } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useUser } from "../../../query/useAuthentication";

const RemainingSubscriptionWithoutMemo = ({ isSubuser }) => {
  const navigate = useNavigate();
  // const [activeSubscription, setActiveSubscription] = useState(true);
  const [profile, setProfile] = useState(
    localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user"))?.profile
  );

  const activeSubscription = profile?.has_active_subscription;
  const expireDate = profile?.subscription_expiration_date;
  const startDate = profile?.subscription_start_date;

  const currentTime = Math.floor(Date.now() / 1000); // Current time in Unix timestamp

  // Calculate the difference in seconds
  const remainingSeconds = expireDate - currentTime;
  const totalSeconds = expireDate - startDate;

  const remainingDays = Math.floor(remainingSeconds / (60 * 60 * 24));

  const totalDays = Math.floor(totalSeconds / (60 * 60 * 24));

  useUser({
    onSuccess: (res) => {
      // setActiveSubscription(res.profile?.has_active_subscription);
      setProfile(res?.profile);
    },
  });

  // useEffect(() => {
  //   const storedUser = localStorage.getItem("user");
  //   if (storedUser) {
  //     const parsedUser = JSON.parse(storedUser);
  //     // setUserInfo(parsedUser);
  //     // setActiveSubscription(parsedUser.profile?.has_active_subscription);
  //     setProfile(parsedUser?.profile);
  //   }
  // }, [localStorage.getItem("user")]);

  const handleGoToPayment = () => {
    isSubuser
      ? navigate("/dashboard/billing")
      : navigate("/dashboard/payment?savedCard=false");
  };

  return (
    <div className="flex flex-col gap-3  mt-5 lg:mt-7/ rounded-[10px] min-h-[120px] mr-5 lg:mr-0 bg-[#f0f5ff] dark:bg-darkLightBg p-5">
      {!isSubuser && !activeSubscription && (
        <p className="text-[#4A4A4A] dark:text-white text-center font-pro text-[15px]">
          You have no subscription.
        </p>
      )}
      {!isSubuser && activeSubscription && (
        <div className="space-y-3">
          <p className="font-pro font-medium text-[15px] text-[#4d4d4d] dark:text-white">
            {profile?.current_plan?.short_text} Plan
          </p>
          <p className="font-pro font-bold text-2xl">
            {remainingDays || "-"}/
            <span className="font-medium text-xl">{totalDays || "-"}</span>{" "}
            <span className="text-sm text-[#777]">days left</span>
          </p>
        </div>
      )}
      {
        <button
          className="bg-newPrimary text-white font-pro text-[15px] rounded-md px-[30px] py-[7px] hover:text-newPrimary hover:bg-white transition-all duration-200 border border-newPrimary dark:bg-darkLightBg flex justify-center items-center"
          onClick={handleGoToPayment}
        >
          {isSubuser ? (
            "View Billing"
          ) : (
            <>
              {activeSubscription ? (
                <p className="flex items-center gap-2">
                  <Rocket className="size-5" /> Upgrade Plan
                </p>
              ) : (
                "Buy Plan"
              )}
            </>
          )}
        </button>
      }
    </div>
  );
};

const RemainingSubscription = React.memo(RemainingSubscriptionWithoutMemo);

export default RemainingSubscription;
