/* eslint-disable react/prop-types */
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Cards from "../../assets/home/cards.png";
import ReviewGroups from "../../assets/home/reviews-group.png";
import MasterDashboard from "../../assets/home/master-dashboard.png";
import UnifiedInbox from "../../assets/home/unified-inbox.png";
import LineEarth from "../../assets/home/line-earth.png";
import Integrations from "../../assets/home/50-integrations.png";
import LineFan from "../../assets/home/line-fan.png";
import Recording from "../../assets/home/record-audio.png";
import AiAssitance from "../../assets/home/ai-assitance.png";
import { Sparkles } from "lucide-react";

const Card = ({ children }) => {
  return <div className="w-full p-5 bg-white rounded-xl">{children}</div>;
};

const CardHeading = ({ children }) => {
  return (
    <p className="font-poppins text-[22px] text-black font-semibold lg:leading-[26.8px] mb-2">
      {children}
    </p>
  );
};

const CardBody = ({ children }) => {
  return <p className="font-poppins text-sm text-black ">{children}</p>;
};

const ClientComponent = () => {
  return (
    <>
      <h2 className="text-center font-semibold text-[24px] md:text-[44px] text-[#333333] font-poppins my-16">
        What We Offer
      </h2>
      <ResponsiveMasonry
        columnsCountBreakPoints={{
          200: 1,
          1180: 3,
        }}
        className="mt-5"
      >
        <Masonry gutter="20px">
          <Card>
            <div>
              <img src={Cards} className="w-full" loading="lazy" />
              <CardHeading>{"MagicCards"}</CardHeading>
              <CardBody>
                {"NFC & QR enabled cards with customisable Magiclink"}
              </CardBody>
            </div>
          </Card>
          <Card>
            <div>
              <CardHeading>
                <p className="flex items-center gap-3">
                  <Sparkles className="text-[#e5484d]" /> AI-Agent Assitance
                </p>
              </CardHeading>
              <img src={AiAssitance} className="w-full mt-5" loading="lazy" />
            </div>
          </Card>
          <Card>
            <div>
              <div className="flex items-start justify-between gap-5 mb-7">
                <CardBody>
                  {
                    <p>
                      Track - Customise <br /> & Send anywhere
                    </p>
                  }
                </CardBody>
                <img
                  src={
                    "https://magicreviewstorage.blob.core.windows.net/assets/email_assets/magiclink.png"
                  }
                  className="w-1/2"
                  loading="lazy"
                />
              </div>

              <CardHeading>{"MagicLinks"}</CardHeading>
              <CardBody>
                {
                  "MagicLinks that allow you to request reviews anywhere your customers are."
                }
              </CardBody>
            </div>
          </Card>
          <Card>
            <div className="xl:py-3.5 2xl:py-5">
              <CardHeading>
                {
                  <p>
                    Organization Account <br /> Overview
                  </p>
                }
              </CardHeading>
              <CardBody>
                {"Enable seamless management across multiple locations"}
              </CardBody>
              <img
                src={MasterDashboard}
                className="w-full mt-5"
                loading="lazy"
              />
            </div>
          </Card>
          <Card>
            <div>
              <img src={UnifiedInbox} className="w-[90%] mb-5 mx-auto" />
              <CardHeading>
                {
                  <p>
                    Unified Inbox / <br className="2xl:hidden" /> MagicBox
                  </p>
                }
              </CardHeading>
              <CardBody>
                {"Inbox where you can manage all of your reviews in one place"}
              </CardBody>
            </div>
          </Card>
          <Card>
            <div>
              <div className="flex justify-between">
                <div></div>
                {/* <div className="flex flex-col gap-3 w-1/2">
                <div className="px-5 h-8 border-newPrimary bg-[#f0f3ff] font-poppins font-medium text-[10px] text-newPrimary flex justify-center items-center text-center w-fit rounded-full border ">
                  Add Questions
                </div>
                <div className="px-5 h-8 border-newPrimary bg-[#f0f3ff] font-poppins font-medium text-[10px] text-newPrimary flex justify-center items-center text-center w-fit rounded-full border ">
                  AI Question Generator
                </div>
                <div className="px-5 h-8 border-newPrimary bg-[#f0f3ff] font-poppins font-medium text-[10px] text-newPrimary flex justify-center items-center text-center w-fit rounded-full border ">
                  Pre-build questions
                </div>
              </div> */}
                <img
                  src={Integrations}
                  className="3xl:w-1/2 w-[60%]"
                  loading="lazy"
                />
              </div>

              <div className=" 2xl:-mt-[118px] xl:-mt-16">
                <CardHeading>
                  {
                    <>
                      <p className="text-[34px]">50+</p>
                      <p>Integrations</p>
                    </>
                  }
                </CardHeading>
                <CardBody>
                  {
                    <p>
                      Integrate with 50+ platforms and <br /> improve your
                      brands reputation.
                    </p>
                  }
                </CardBody>
              </div>
            </div>
          </Card>
          {/* <Card>
          <div className="relative pt-20">
            <img src={LineEarth} className="w-1/2 absolute top-0 right-0" />
            <CardHeading>
              {
                <p>
                  Global Language / <br /> Support
                </p>
              }
            </CardHeading>
            <CardBody>
              {
                "Engage globally with support for multiple languages, maintaining brand consistency across markets."
              }
            </CardBody>
          </div>
        </Card> */}
          <Card>
            <div className="flex items-center justify-between py-5 2xl:py-9 3xl:py-[39px]">
              <div className="font-poppins font-medium text-[9px] flex-shrink-0 border-l-4 border-l-[#4f75fa]/50 px-4">
                <p className="text-[#9dace2]">Review automation tool</p>
                <p className="text-[#627ad0]">Improve your Local visibility</p>
                <p className="text-[#2f479d]">Rank higher on Local SEO</p>
                <p className="text-[#1f2e66]">Improve local search rankings</p>
                <p className="text-[#2f479d]">Automated review responses</p>
                <p className="text-[#627ad0]">Boost your business ratings</p>
                <p className="text-[#9dace2]">Customer feedback</p>
              </div>
              <div className="text-right">
                <CardHeading>{<p>Local SEO-Enhanced Reviews</p>}</CardHeading>
                <CardBody>
                  {"Get keyword-rich reviews to boost local SEO visibility"}
                </CardBody>
              </div>
            </div>
          </Card>
          <Card>
            <div>
              <img
                src={LineFan}
                className="w-[90%] 2xl:w-[85%] 3xl:w-[71%] mx-auto mb-1"
              />
              <CardHeading>
                {<p className="text-center">Automated Replies</p>}
              </CardHeading>
              <CardBody>
                <p className="text-center">
                  {
                    "Increase customer satisfaction by 25% with MagicReview’s Auto-Response Agent"
                  }
                </p>
              </CardBody>
              <img
                src={LineFan}
                className="w-[90%] 2xl:w-[85%] mx-auto -rotate-180 mt-1"
                loading="lazy"
              />
            </div>
          </Card>
          <Card>
            <div className="flex justify-between items-center">
              <div>
                <CardHeading>
                  {
                    <p>
                      Multilingual <br /> Voice to Review
                    </p>
                  }
                </CardHeading>
                <CardBody>
                  {"Turn customer voices into written reviews in any language."}
                </CardBody>
              </div>
              <img src={Recording} className="w-1/2 mb-1" loading="lazy" />
            </div>
          </Card>
        </Masonry>
      </ResponsiveMasonry>
    </>
  );
};

export default ClientComponent;
