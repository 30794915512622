import { useState } from "react";
import {
  useDeleteGmb,
  useGetAirbnbSettings,
  useGetBookingSettings,
  useGetGmbInfo,
  useGetSettings,
  useGetTripSettings,
  useGetTrustPilotSettings,
  useGetYelpSettings,
  usePostAirbnbToggle,
  usePostBookingToggle,
  usePostEnableAirbnbSupport,
  usePostEnableBookingSupport,
  usePostEnableSupport,
  usePostEnableTripSupport,
  usePostEnableTrustPilotSupport,
  usePostEnableYelpSupport,
  usePostGmbAcc,
  usePostToggle,
  usePostTripToggle,
  usePostTrustPilotToggle,
  usePostYelpToggle,
  usePutUpdateAirbnbSettings,
  usePutUpdateBookingSettings,
  usePutUpdateSettings,
  usePutUpdateTripSettings,
  usePutUpdateTrustPilotSettings,
  usePutUpdateYelpSettings,
} from "../../../query/useAuthentication";
import Layout from "../component/Layout";
import LinkedAccount from "./components/LinkedAccount";
import Google from "../../../assets/reviewservices/google.svg";
import TrustPilot from "../../../assets/reviewservices/trustpilot.svg";
import Yelp from "../../../assets/reviewservices/yelp.svg";
import Booking from "../../../assets/reviewservices/booking.svg";
import Airbnb from "../../../assets/reviewservices/airbnb.svg";
import TripAdvisor from "../../../assets/reviewservices/tripadvisor.svg";
import { toast } from "sonner";
import { Building2, Ellipsis, Link, MapPin, Trash2, User } from "lucide-react";
import Loading, { LogoLoading } from "../../../components/loading/loading";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { Button } from "../../../components/ui/button";
import GoogleMapsIcon from "../../../assets/icons/GoogleMapsIcon";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import IconButton from "../component/IconButton";
import { DeleteModal } from "../component/modal";
import axios from "axios";
import GmbIntegration from "./components/GmbIntegration";
import { useQueryClient } from "@tanstack/react-query";

const Integration = () => {
  const queryClient = useQueryClient();
  const [isSupport, setIsSupport] = useState(false);
  const [isActiveGoogle, setIsActiveGoogle] = useState(false);
  const [isOpenGoogleModal, setIsOpenGoogleModal] = useState(false);
  const [googleLink, setGoogleLink] = useState("");

  const [isTrustPilotSupport, setIsTrustPilotSupport] = useState(false);
  const [isActiveTrustpilot, setIsActiveTrustpilot] = useState(false);
  const [isOpenTrustpilotModal, setIsOpenTrustpilotModal] = useState(false);
  const [trustpilotLink, setTrustpilotLink] = useState("");

  const [isYelpSupport, setIsYelpSupport] = useState(false);
  const [isActiveYelp, setIsActiveYelp] = useState(false);
  const [isOpenYelpModal, setIsOpenYelpModal] = useState(false);
  const [yelpLink, setYelpLink] = useState("");

  const [isBookingSupport, setIsBookingSupport] = useState(false);
  const [isActiveBooking, setIsActiveBooking] = useState(false);
  const [isOpenBookingModal, setIsOpenBookingModal] = useState(false);
  const [bookingLink, setBookingLink] = useState("");

  const [isAirSupport, setIsAirSupport] = useState(false);
  const [isActiveAir, setIsActiveAir] = useState(false);
  const [isOpenAirModal, setIsOpenAirModal] = useState(false);
  const [airLink, setAirLink] = useState("");

  const [isTripSupport, setIsTripSupport] = useState(false);
  const [isActiveTrip, setIsActiveTrip] = useState(false);
  const [isOpenTripModal, setIsOpenTripModal] = useState(false);
  const [tripLink, setTripLink] = useState("");

  const [isActiveCustom, setIsActiveCustom] = useState(false);
  const [isOpenCustomModal, setIsOpenCustomModal] = useState(false);
  const [customLink, setCustomLink] = useState("");

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const {
    data: settings,
    refetch: refetchSettings,
    isLoading,
    isFetching,
  } = useGetSettings({
    onSuccess: (res) => {
      if (res?.is_enabled === "False") {
        setIsSupport(false);
      } else {
        setIsSupport(true);
      }

      setIsActiveGoogle(res.active);
      setGoogleLink(res.review_url);
    },
    onError: (err) => {
      if (err.response.status === 403) {
        setIsSupport(false);
      }
    },
  });
  const { mutate: updateSettings, isLoading: updateSettingsLoading } =
    usePutUpdateSettings();
  const { mutate: enableSupport, isLoading: enableSupportLoading } =
    usePostEnableSupport();
  const { mutate: toggle, isLoading: toggleLoading } = usePostToggle();

  const { data: settingsYelp, refetch: refetchYelpSettings } =
    useGetYelpSettings({
      onSuccess: (res) => {
        if (res?.is_enabled === "False") {
          setIsYelpSupport(false);
        } else {
          setIsYelpSupport(true);
        }
        setIsActiveYelp(res.active);
        setYelpLink(res.review_url);
      },
      onError: (err) => {
        if (err.response.status === 403) {
          setIsYelpSupport(false);
        }
      },
    });
  const { mutate: updateYelpSettings, isLoading: updateYelpSettingsLoading } =
    usePutUpdateYelpSettings();
  const { mutate: enableYelpSupport, isLoading: enableYelpSupportLoading } =
    usePostEnableYelpSupport();
  const { mutate: yelpToggle, isLoading: toggleYelpLoading } =
    usePostYelpToggle();

  const { data: settingsTrustPilot, refetch: refetchTrustPilotSettings } =
    useGetTrustPilotSettings({
      onSuccess: (res) => {
        if (res?.is_enabled === "False") {
          setIsTrustPilotSupport(false);
        } else {
          setIsTrustPilotSupport(true);
        }
        setIsActiveTrustpilot(res.active);
        setTrustpilotLink(res.review_url);
      },
      onError: (err) => {
        if (err.response.status === 403) {
          setIsTrustPilotSupport(false);
        }
      },
    });
  const {
    mutate: updateTrustPilotSettings,
    isLoading: updateTrustPilotSettingsLoading,
  } = usePutUpdateTrustPilotSettings();
  const {
    mutate: enableTrustPilotSupport,
    isLoading: enableTrustPilotSupportLoading,
  } = usePostEnableTrustPilotSupport();
  const { mutate: trustPilotToggle, isLoading: toggleTrustPilotLoading } =
    usePostTrustPilotToggle();

  const { data: settingsBooking, refetch: refetchBookingSettings } =
    useGetBookingSettings({
      onSuccess: (res) => {
        if (res?.is_enabled === "False") {
          setIsBookingSupport(false);
        } else {
          setIsBookingSupport(true);
        }
        setIsActiveBooking(res.active);
        setBookingLink(res.review_url);
      },
      onError: (err) => {
        if (err.response.status === 403) {
          setIsBookingSupport(false);
        }
      },
    });
  const {
    mutate: updateBookingSettings,
    isLoading: updateBookingSettingsLoading,
  } = usePutUpdateBookingSettings();
  const {
    mutate: enableBookingSupport,
    isLoading: enableBookingSupportLoading,
  } = usePostEnableBookingSupport();
  const { mutate: bookingToggle, isLoading: toggleBookingLoading } =
    usePostBookingToggle();

  const { data: settingsAirbnb, refetch: refetchAirbnbSettings } =
    useGetAirbnbSettings({
      onSuccess: (res) => {
        if (res?.is_enabled === "False") {
          setIsAirSupport(false);
        } else {
          setIsAirSupport(true);
        }
        setIsActiveAir(res.active);
        setAirLink(res.review_url);
      },
      onError: (err) => {
        if (err.response.status === 403) {
          setIsAirSupport(false);
        }
      },
    });
  const {
    mutate: updateAirbnbSettings,
    isLoading: updateAirbnbSettingsLoading,
  } = usePutUpdateAirbnbSettings();
  const { mutate: enableAirbnbSupport, isLoading: enableAirbnbSupportLoading } =
    usePostEnableAirbnbSupport();
  const { mutate: airbnbToggle, isLoading: toggleAirbnbLoading } =
    usePostAirbnbToggle();

  const { data: settingsTrip, refetch: refetchTripSettings } =
    useGetTripSettings({
      onSuccess: (res) => {
        if (res?.is_enabled === "False") {
          setIsTripSupport(false);
        } else {
          setIsTripSupport(true);
        }
        setIsActiveTrip(res.active);
        setTripLink(res.review_url);
      },
      onError: (err) => {
        if (err.response.status === 403) {
          setIsTripSupport(false);
        }
      },
    });
  const { mutate: updateTripSettings, isLoading: updateTripSettingsLoading } =
    usePutUpdateTripSettings();
  const { mutate: enableTripSupport, isLoading: enableTripSupportLoading } =
    usePostEnableTripSupport();
  const { mutate: tripToggle, isLoading: toggleTripLoading } =
    usePostTripToggle();

  const handleEnableSupport = () => {
    enableSupport(
      {},
      {
        onSuccess: (res) => {
          refetchSettings();
          toast.success("Enabled account integration successfully");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleEnableYelpSupport = () => {
    enableYelpSupport(
      {},
      {
        onSuccess: (res) => {
          refetchYelpSettings();
          toast.success("Enabled account integration successfully");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleEnableTrustPilotSupport = () => {
    enableTrustPilotSupport(
      {},
      {
        onSuccess: (res) => {
          refetchTrustPilotSettings();
          toast.success("Enabled account integration successfully");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleEnableBookingSupport = () => {
    enableBookingSupport(
      {},
      {
        onSuccess: (res) => {
          refetchBookingSettings();
          toast.success("Enabled account integration successfully");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleEnableAirbnbSupport = () => {
    enableAirbnbSupport(
      {},
      {
        onSuccess: (res) => {
          refetchAirbnbSettings();
          toast.success("Enabled account integration successfully");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleEnableTripSupport = () => {
    enableTripSupport(
      {},
      {
        onSuccess: (res) => {
          refetchTripSettings();
          toast.success("Enabled account integration successfully");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const toggleGoogle = () => {
    toggle(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchSettings();
          queryClient.invalidateQueries(["getIntegrations"]);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const toggleTrustpilot = () => {
    trustPilotToggle(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchTrustPilotSettings();
          queryClient.invalidateQueries(["getIntegrations"]);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };
  const toggleYelp = () => {
    yelpToggle(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchYelpSettings();
          queryClient.invalidateQueries(["getIntegrations"]);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const toggleBooking = () => {
    bookingToggle(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchBookingSettings();
          queryClient.invalidateQueries(["getIntegrations"]);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const toggleAirbnb = () => {
    airbnbToggle(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchAirbnbSettings();
          queryClient.invalidateQueries(["getIntegrations"]);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const toggleTrip = () => {
    tripToggle(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchTripSettings();
          queryClient.invalidateQueries(["getIntegrations"]);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleUpdateSettings = () => {
    updateSettings(
      {
        review_url: googleLink,
        place_id: null,
      },
      {
        onSuccess: (res) => {
          refetchSettings();
          toast.success("Updated google review link successfully");
          setIsOpenGoogleModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleUpdateYelpSettings = () => {
    updateYelpSettings(
      {
        review_url: yelpLink,
      },
      {
        onSuccess: (res) => {
          refetchYelpSettings();
          toast.success("Updated yelp review link successfully");
          setIsOpenYelpModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleUpdateTrustPilotSettings = () => {
    updateTrustPilotSettings(
      {
        review_url: trustpilotLink,
      },
      {
        onSuccess: (res) => {
          refetchTrustPilotSettings();
          toast.success("Updated trust pilot link successfully");
          setIsOpenTrustpilotModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleUpdateBookingSettings = () => {
    updateBookingSettings(
      {
        review_url: bookingLink,
      },
      {
        onSuccess: (res) => {
          refetchBookingSettings();
          toast.success("Updated booking review link successfully");
          setIsOpenBookingModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleUpdateAirbnbSettings = () => {
    updateAirbnbSettings(
      {
        review_url: airLink,
      },
      {
        onSuccess: (res) => {
          refetchAirbnbSettings();
          toast.success("Updated airbnb review link successfully");
          setIsOpenAirModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleUpdateTripSettings = () => {
    updateTripSettings(
      {
        review_url: tripLink,
      },
      {
        onSuccess: (res) => {
          refetchTripSettings();
          toast.success("Updated Trip Advisor review link successfully");
          setIsOpenTripModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const [gmb, setGmb] = useState();
  const [isOpenGmbIntegration, setIsOpenGmbIntegration] = useState(false);
  const [refreshToken, setRefreshToken] = useState("");

  const {
    data: gmbInfo,
    isLoading: gmbInfoLoading,
    refetch: refetchGmbInfo,
  } = useGetGmbInfo();

  const { mutate: deleteGmb, isLoading: deleteGmbLoading } = useDeleteGmb();

  const handleDeleteGmb = () => {
    deleteGmb(
      {},
      {
        onSuccess: (res) => {
          refetchGmbInfo();
          toast.success(res.message);
          setIsOpenDeleteModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const { mutate: gmbAcc, isLoading: gmbAccLoading } = usePostGmbAcc();

  const googleClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
  const googleClientSecret = import.meta.env.VITE_GOOGLE_CLIENT_SECRET;

  const handleGoogleAccess = useGoogleLogin({
    onSuccess: async (res) => {
      try {
        const response = await axios.post(
          "https://oauth2.googleapis.com/token",
          {
            client_id: googleClientId,
            client_secret: googleClientSecret,
            grant_type: "authorization_code",
            access_type: "offline",
            code: res?.code,
            redirect_uri: "postmessage",
          }
        );

        const { refresh_token } = response.data;
        // Save the refresh token securely
        if (refresh_token) {
          setRefreshToken(refresh_token);
          gmbAcc(
            {
              refresh_token: refresh_token,
            },
            {
              onSuccess: (res) => {
                setGmb(res);
                setIsOpenGmbIntegration(true);
                queryClient.invalidateQueries(["getIntegrations"]);
              },
              onError: (err) => {
                if (err.response.data.message) {
                  toast.error(err.response.data.message);
                } else {
                  toast.error(
                    `Something went wrong, try again later ${err.response.status}`
                  );
                }
              },
            }
          );
        } else {
          toast.error("No token");
        }
      } catch (error) {
        toast.error(`Error fetching refresh token: ${error}`);
      }
    },
    onError: (err) => {
      console.log(err);
    },
    scope: "https://www.googleapis.com/auth/business.manage",
    flow: "auth-code",
  });

  const toggleCustom = () => {
    setIsActiveCustom((prev) => !prev);
  };

  const handleNavigate = (url) => {
    window.open(url);
  };

  if (gmbAccLoading) {
    return <LogoLoading />;
  }

  return (
    <Layout title="Integrations">
      <div className="my-5 w-full dark:bg-darkLightBg rounded-[16px] py-5 lg:py-8 min-h-[calc(100vh-160px)] px-5 lg:px-[30.2px]">
        {isLoading || isFetching || gmbInfoLoading ? (
          <Loading />
        ) : (
          <>
            <div className="">
              <p className="font-pro font-medium text-lg">Link your accounts</p>
            </div>
            <div>
              {gmbInfo?.GMB_INTEGRATION ? (
                <div className="bg-white border border-[#e7e4e4] dark:border-[#454545] dark:bg-darkLightBg rounded-xl p-5 lg:p-7 mt-5">
                  <div className="flex justify-between items-center">
                    <p className="font-pro font-medium text-lg">
                      Google My Business
                    </p>
                    <DropdownMenu>
                      <DropdownMenuTrigger>
                        <IconButton>
                          <Ellipsis className="w-5 h-5 cursor-pointer dark:text-white text-[#595959]" />
                        </IconButton>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="dark:bg-darkLightBg dark:shadow-md">
                        <DropdownMenuItem
                          className="flex items-center gap-2  pr-4 text-[15px] text-red-500 hover:text-red-500"
                          onClick={() => {
                            setIsOpenDeleteModal(true);
                          }}
                        >
                          <IconButton>
                            <Trash2 className="w-4 h-4 cursor-pointer text-red-500" />
                          </IconButton>
                          Remove GMB Integration
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                  <hr className="my-5" />
                  <div className="flex flex-wrap justify-between items-center">
                    <div className="flex flex-wrap items-center gap-5 lg:gap-7">
                      <div className="flex items-center gap-3">
                        <User className="size-5 text-[#737373]" />
                        <p className="font-pro  text-[#252323]">
                          {gmbInfo?.data?.account_name}{" "}
                        </p>
                      </div>
                      <div className="flex items-center gap-3">
                        <Building2 className="size-5 text-[#737373]" />
                        <p className="font-pro  text-[#252323]">
                          {gmbInfo?.data?.title}{" "}
                        </p>
                      </div>
                      <div className="flex items-center gap-3">
                        <MapPin className="size-5 text-[#737373]" />
                        <p className="font-pro  text-[#252323]">
                          {gmbInfo?.data?.administrative_area}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-wrap items-center gap-5">
                      <Button
                        size="icon"
                        variant="secondary"
                        onClick={() =>
                          handleNavigate(gmbInfo?.data?.new_review_uri)
                        }
                      >
                        <Link className="size-5" />
                      </Button>
                      <Button
                        size="icon"
                        variant="secondary"
                        onClick={() => handleNavigate(gmbInfo?.data?.maps_uri)}
                      >
                        <GoogleMapsIcon className="size-5" />
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                // <button className="rounded-[8px] text-white bg-newPrimary border border-newPrimary text-[14px] font-normal py-[10px] w-fit dark:hover:bg-darkBg hover:bg-white hover:text-newPrimary transition-all duration-200  mb-[15px] add-button flex items-center justify-center gap-2 px-5">
                //   Setup Google Integration
                // </button>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 lg:gap-x-10 mt-5">
                  <div className="bg-white border border-[#e7e4e4] dark:border-[#454545] dark:bg-darkLightBg rounded-xl h-16 flex justify-between items-center px-5">
                    <div className="flex items-center gap-x-2">
                      <img src={"/gmb.svg"} className="w-6 h-6" />
                      <p className="font-pro font-medium text-[15px]">
                        Google My Business
                      </p>
                    </div>
                    <button
                      className="bg-newPrimary rounded-lg text-white px-2 py-1.5 flex justify-center items-center w-[80px] font-poppins text-sm"
                      onClick={handleGoogleAccess}
                    >
                      Link
                    </button>
                  </div>
                </div>
              )}
            </div>
            <hr className="mt-8" />
            <div className="mt-5">
              <p className="font-pro font-medium text-lg">
                Review redirection links
              </p>
            </div>

            <div
              className="
             grid grid-cols-1 md:grid-cols-2 gap-y-5 lg:gap-x-10 mt-5
          "
            >
              <LinkedAccount
                name={"Google"}
                image={Google}
                isActiveLink={isActiveGoogle}
                setIsActiveLink={setIsActiveGoogle}
                isOpenModal={isOpenGoogleModal}
                setIsOpenmodal={setIsOpenGoogleModal}
                link={googleLink}
                setLink={setGoogleLink}
                toggle={toggleGoogle}
                isLoading={updateSettingsLoading}
                isEnableLoading={enableSupportLoading}
                isToggleLoading={toggleLoading}
                handleClick={handleUpdateSettings}
                isComingSoon={false}
                isSupport={isSupport}
                onEnableSupport={handleEnableSupport}
              />
              <LinkedAccount
                name={"Trustpilot"}
                image={TrustPilot}
                isActiveLink={isActiveTrustpilot}
                setIsActiveLink={setIsActiveTrustpilot}
                isOpenModal={isOpenTrustpilotModal}
                setIsOpenmodal={setIsOpenTrustpilotModal}
                link={trustpilotLink}
                setLink={setTrustpilotLink}
                toggle={toggleTrustpilot}
                isLoading={updateTrustPilotSettingsLoading}
                isEnableLoading={enableTrustPilotSupportLoading}
                isToggleLoading={toggleTrustPilotLoading}
                handleClick={handleUpdateTrustPilotSettings}
                isComingSoon={false}
                isSupport={isTrustPilotSupport}
                onEnableSupport={handleEnableTrustPilotSupport}
              />
              <LinkedAccount
                name={"Yelp"}
                image={Yelp}
                isActiveLink={isActiveYelp}
                setIsActiveLink={setIsActiveYelp}
                isOpenModal={isOpenYelpModal}
                setIsOpenmodal={setIsOpenYelpModal}
                link={yelpLink}
                setLink={setYelpLink}
                toggle={toggleYelp}
                isLoading={updateYelpSettingsLoading}
                isEnableLoading={enableYelpSupportLoading}
                isToggleLoading={toggleYelpLoading}
                handleClick={handleUpdateYelpSettings}
                isComingSoon={false}
                isSupport={isYelpSupport}
                onEnableSupport={handleEnableYelpSupport}
              />
              <LinkedAccount
                name={"Booking"}
                image={Booking}
                isActiveLink={isActiveBooking}
                setIsActiveLink={setIsActiveBooking}
                isOpenModal={isOpenBookingModal}
                setIsOpenmodal={setIsOpenBookingModal}
                link={bookingLink}
                setLink={setBookingLink}
                toggle={toggleBooking}
                isLoading={updateBookingSettingsLoading}
                isEnableLoading={enableBookingSupportLoading}
                isToggleLoading={toggleBookingLoading}
                handleClick={handleUpdateBookingSettings}
                isComingSoon={false}
                isSupport={isBookingSupport}
                onEnableSupport={handleEnableBookingSupport}
              />
              <LinkedAccount
                name={"Airbnb"}
                image={Airbnb}
                isActiveLink={isActiveAir}
                setIsActiveLink={setIsActiveAir}
                isOpenModal={isOpenAirModal}
                setIsOpenmodal={setIsOpenAirModal}
                link={airLink}
                setLink={setAirLink}
                toggle={toggleAirbnb}
                isLoading={updateAirbnbSettingsLoading}
                isEnableLoading={enableAirbnbSupportLoading}
                isToggleLoading={toggleAirbnbLoading}
                handleClick={handleUpdateAirbnbSettings}
                isComingSoon={false}
                isSupport={isAirSupport}
                onEnableSupport={handleEnableAirbnbSupport}
              />
              <LinkedAccount
                name={"Trip Advisor"}
                image={TripAdvisor}
                isActiveLink={isActiveTrip}
                setIsActiveLink={setIsActiveTrip}
                isOpenModal={isOpenTripModal}
                setIsOpenmodal={setIsOpenTripModal}
                link={tripLink}
                setLink={setTripLink}
                toggle={toggleTrip}
                isLoading={updateTripSettingsLoading}
                isEnableLoading={enableTripSupportLoading}
                isToggleLoading={toggleTripLoading}
                handleClick={handleUpdateTripSettings}
                isComingSoon={false}
                isSupport={isTripSupport}
                onEnableSupport={handleEnableTripSupport}
              />
              {/* <LinkedAccount
            name={"Custom Link"}
            icon={<Link className="w-5 h-5" />}
            isActiveLink={isActiveCustom}
            setIsActiveLink={setIsActiveCustom}
            isOpenModal={isOpenCustomModal}
            setIsOpenmodal={setIsOpenCustomModal}
            link={customLink}
            setLink={setCustomLink}
            toggle={toggleCustom}
            isLoading={false}
            handleClick={handleUpdateSettings}
            isComingSoon={false}
            isSupport={isSupport}
            onEnableSupport={handleEnableSupport}
          /> */}
              {/* <GoogleLogin
              onSuccess={(res) => handleGoogleLogin(res)}
              onError={(err) => console.log(err)}
              size="large"
              shape="pill"
              width={300}
              text="continue_with"
              useOneTap
              scope="https://www.googleapis.com/auth/business.manage"
            />
            <button onClick={(res) => handleGoogleAccess(res)}>
              Google Access
            </button> */}
            </div>
          </>
        )}
      </div>
      <DeleteModal
        openModal={isOpenDeleteModal}
        setOpenModal={setIsOpenDeleteModal}
        title={"Are you sure you want to remove GMB integration?"}
        handleDelete={handleDeleteGmb}
        isLoading={deleteGmbLoading}
      />
      <GmbIntegration
        openModal={isOpenGmbIntegration}
        setOpenModal={setIsOpenGmbIntegration}
        gmbInfo={gmb}
        refetchGmbInfo={refetchGmbInfo}
        refreshToken={refreshToken}
      />
    </Layout>
  );
};

export default Integration;
