/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Progress } from "../../../../components/ui/progress";
import { CheckCircle, CheckCircle2, Circle } from "lucide-react";
import { useGetGmbInfo, useUser } from "../../../../query/useAuthentication";
import { useNavigate } from "react-router";
import { useDarkMode } from "../../../../context/DarkModeProvider";
import Skeleton from "react-loading-skeleton";
import useWindowSize from "../../../../hooks/useWindowSize";

const Setup = ({ isIntegrated }) => {
  const navigate = useNavigate();
  const { darkMode } = useDarkMode();
  const [progress, setProgress] = useState(25);
  const [hasPlan, setHasPlan] = useState(true);
  const [hasGmb, setHasGmb] = useState(true);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;
  const isLargerScreen = windowSize.width >= 1920;

  const handleNavigate = (url) => {
    navigate(url);
  };

  const { isLoading: userLoading } = useUser({
    onSuccess: (res) => {
      if (res?.profile?.has_active_subscription) {
        setHasPlan(true);
      } else {
        setHasPlan(false);
      }
    },
  });

  const { isLoading: gmbLoading } = useGetGmbInfo({
    onSuccess: (res) => {
      if (res.GMB_INTEGRATION) {
        setHasGmb(true);
      } else {
        setHasGmb(false);
      }
    },
  });

  useEffect(() => {
    if (isIntegrated && hasGmb && hasPlan) {
      setProgress(100);
    } else if (
      (isIntegrated && hasGmb) ||
      (isIntegrated && hasPlan) ||
      (hasGmb && hasPlan)
    ) {
      setProgress(75);
    } else if (isIntegrated || hasGmb || hasPlan) {
      setProgress(50);
    } else {
      setProgress(25);
    }
  }, [isIntegrated, hasGmb, hasPlan]);

  return (
    <div className="flex flex-col w-full p-6 rounded-[12px] bg-white dark:bg-darkBg h-full shadow-sm font-pro">
      {userLoading && gmbLoading ? (
        <Skeleton
          containerClassName="flex-1"
          baseColor={darkMode && "#262626"}
        />
      ) : (
        <>
          <h1 className="text-lg font-semibold">Let&apos;s get you setup!</h1>
          <p className="text-sm text-[#333] dark:text-white">
            Follow these quick steps to get started.
          </p>{" "}
        </>
      )}
      {userLoading && gmbLoading ? (
        <Skeleton
          height={isMobile ? 240 : isLargerScreen ? 350 : 300}
          baseColor={darkMode && "#262626"}
        />
      ) : (
        <>
          <div className="mt-5">
            <Progress
              value={progress}
              className="h-1.5"
              indicatorColor={"#4f75fa"}
            />
            <p className="text-right font-medium text-[#252323] dark:text-white text-xs mt-2">
              {progress}% completed
            </p>
          </div>
          <div className="mt-5 space-y-3">
            <div className="flex items-center gap-3 border border-newPrimary bg-[#f0f5ff] dark:bg-darkLightBg h-9 rounded-[36px] text-sm text-[#252323] dark:text-white pl-2">
              <CheckCircle2 className="text-newPrimary fill-white" /> Set up
              your profile
            </div>

            {hasPlan ? (
              <div className="flex items-center gap-3 border border-newPrimary bg-[#f0f5ff] dark:bg-darkLightBg h-9 rounded-[36px] text-sm text-[#252323] dark:text-white pl-2">
                <CheckCircle2 className="text-newPrimary fill-white" /> Sign up
                for a plan
              </div>
            ) : (
              <div className="flex justify-between items-center gap-3 border h-9 rounded-[36px] text-sm text-[#252323] dark:text-white px-2">
                <div className="flex items-center gap-3">
                  <Circle className="text-[#ebebeb]" /> Sign up for a plan
                </div>
                <p
                  className="underline cursor-pointer"
                  onClick={() =>
                    handleNavigate("/dashboard/payment?savedCard=false")
                  }
                >
                  Link
                </p>
              </div>
            )}

            {isIntegrated ? (
              <div className="flex items-center gap-3 border border-newPrimary bg-[#f0f5ff] h-9 rounded-[36px] text-sm text-[#252323] dark:bg-darkLightBg dark:text-white pl-2">
                <CheckCircle2 className="text-newPrimary fill-white" /> Add your
                redirect link
              </div>
            ) : (
              <div className="flex justify-between items-center gap-3 border h-9 rounded-[36px] text-sm text-[#252323] dark:text-white px-2">
                <div className="flex items-center gap-3">
                  <Circle className="text-[#ebebeb]" /> Add your redirect link
                </div>
                <p
                  className="underline cursor-pointer"
                  onClick={() =>
                    handleNavigate("/dashboard/settings/integrations")
                  }
                >
                  Link
                </p>
              </div>
            )}

            {hasGmb ? (
              <div className="flex items-center gap-3 border border-newPrimary bg-[#f0f5ff] dark:bg-darkLightBg h-9 rounded-[36px] text-sm text-[#252323] dark:text-white pl-2">
                <CheckCircle2 className="text-newPrimary fill-white" /> Set up
                Google Integration.
              </div>
            ) : (
              <div className="flex justify-between items-center gap-3 border h-9 rounded-[36px] text-sm text-[#252323] dark:text-white px-2">
                <div className="flex items-center gap-3">
                  <Circle className="text-[#ebebeb]" /> Set up Google
                  Integration.
                </div>
                <p
                  className="underline cursor-pointer"
                  onClick={() =>
                    handleNavigate("/dashboard/settings/integrations")
                  }
                >
                  Link
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Setup;
