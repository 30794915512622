import Skeleton from "react-loading-skeleton";
import { useDarkMode } from "../../../context/DarkModeProvider";
import { useGetBillingStats } from "../../../query/useMaster";
import Layout from "../component/Layout";
import { dayFormatter, nextDay } from "../../../utils/formatter";
import NonContinuousProgressBar from "../../master/components/NonContinuousProgressBar";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { Infinity } from "lucide-react";
import {
  useGetSubUserBilling,
  useGetSubUserCharges,
  useGetSubUserCreditRequests,
} from "../../../query/useAuthentication";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { Button } from "../../../components/ui/button";
import { useState } from "react";
import CreditRequestModal from "./components/CreditRequestModal";
import ScreenshotModal from "../../master/components/ScreenshotModal";

const SubUserBilling = () => {
  const { darkMode } = useDarkMode();
  const [isOpenCreditReq, setIsOpenCreditReq] = useState(false);
  const [isOpenScreenshot, setIsOpenScreenshot] = useState(0);

  const { data: billingStats, isLoading: billingStatsLoading } =
    useGetSubUserBilling();

  const { data: charges, isLoading: chargesLoading } = useGetSubUserCharges();

  const { data: creditRequests, isLoading: creditRequestsLoading } =
    useGetSubUserCreditRequests();

  const data = [
    { name: "A", value: billingStats?.total_usage_used, color: "#4F75FA" },
    {
      name: "B",
      value:
        billingStats?.usage_limit - billingStats?.total_usage_used < 0
          ? 0
          : billingStats?.usage_limit - billingStats?.total_usage_used,
      color: "#F0E5FC",
    },
  ];

  return (
    <Layout title={"Billing"}>
      <div className="flex flex-col mt-5 w-full dark:bg-darkLightBg rounded-[16px] py-5 sm:py-[31px] min-h-[calc(100vh-140px)] px-5 lg:px-[30.2px] font-pro ">
        <div className="mb-7">
          <Button className="w-auto" onClick={() => setIsOpenCreditReq(true)}>
            Request Credit
          </Button>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:max-w-[890px]">
          <div className="flex w-full flex-col rounded-[12px] p-5 bg-white dark:bg-darkBg shadow-sm border border-[#ebebeb] dark:border-none h-full">
            {billingStatsLoading ? (
              <Skeleton
                containerClassName="flex-1"
                inline
                className="w-full"
                baseColor={darkMode && "#262626"}
              />
            ) : (
              <>
                <p className="font-medium text-lg">Credits Used</p>
              </>
            )}
            {billingStatsLoading ? (
              <Skeleton
                containerClassName="flex-1"
                height={50}
                inline
                className="w-full mt-5"
                baseColor={darkMode && "#262626"}
              />
            ) : (
              <>
                <p className="font-semibold text-[38px] mt-8 mb-3">
                  {billingStats?.total_usage_used}{" "}
                  <span className="text-base text-[#808080] font-normal">
                    of {billingStats?.credits_allocated}
                  </span>
                </p>
                <NonContinuousProgressBar
                  progress={
                    (billingStats?.total_usage_used /
                      billingStats?.credits_allocated) *
                    100
                  }
                />
              </>
            )}
          </div>
          {/* <div className="flex w-full flex-col rounded-[12px] p-5 bg-white dark:bg-darkBg shadow-sm border border-[#ebebeb] dark:border-none h-full">
            {billingStatsLoading ? (
              <Skeleton
                containerClassName="flex-1"
                inline
                className="w-full"
                baseColor={darkMode && "#262626"}
              />
            ) : (
              <>
                <p className="font-medium text-lg">Credits Remaining</p>
              </>
            )}
            {billingStatsLoading ? (
              <Skeleton
                containerClassName="flex-1"
                height={50}
                inline
                className="w-full mt-5"
                baseColor={darkMode && "#262626"}
              />
            ) : (
              <>
                <p className="font-semibold text-[38px] mt-8 mb-3">
                  {billingStats?.credits_allocated -
                    billingStats?.total_usage_used}{" "}
                  <span className="text-base text-[#808080] font-normal">
                    of {billingStats?.credits_allocated}
                  </span>
                </p>
                <NonContinuousProgressBar
                  progress={
                    ((billingStats?.credits_allocated -
                      billingStats?.total_usage_used) /
                      billingStats?.credits_allocated) *
                    100
                  }
                />
              </>
            )}
          </div> */}

          {/* <div className="flex flex-col w-full lg:max-w-[430px] gap-5">
            <div className="flex w-full flex-col rounded-[12px] p-5 bg-white dark:bg-darkBg shadow-sm border border-[#ebebeb] dark:border-none">
              {billingStatsLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  inline
                  className="w-full mt-5"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <p className="font-medium text-lg">Next billing date</p>
              )}

              {billingStatsLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  height={50}
                  inline
                  className="w-full mt-5"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <p className="font-semibold text-[38px] mt-5 mb-3">
                  {billingStats?.end_date && nextDay(billingStats?.end_date)}
                </p>
              )}
            </div>
            <div className="flex w-full flex-col rounded-[12px] p-5 bg-white dark:bg-darkBg shadow-sm border border-[#ebebeb] dark:border-none h-full">
              {billingStatsLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  height={50}
                  inline
                  className="w-full mt-5"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <>
                  <p className="font-medium text-lg">Credit Limit</p>
                  <p className="text-sm text-[#808080]">
                    Maximum available credit
                  </p>
                </>
              )}
              {billingStatsLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  height={70}
                  inline
                  className="w-full mt-5"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <div className="w-full relative">
                  <ResponsiveContainer
                    width="100%"
                    height={140}
                    className="w-full xl:scale-110"
                  >
                    <PieChart className="my-0 lg:mt-10 mx-auto">
                      <Pie
                        dataKey="value"
                        startAngle={180}
                        endAngle={0}
                        data={data}
                        cx={"50%"}
                        cy={"75%"}
                        innerRadius={80}
                        outerRadius={90}
                        fill="#7190FF"
                        stroke="none"
                      >
                        {data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                  <div className="flex flex-col justify-center items-center absolute top-1/2 translate-y-[30%] left-1/2 -translate-x-1/2">
                    <p className="font-semibold text-[28px]">
                      {billingStats?.usage_limit}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div> */}
        </div>
        <div className="mt-7">
          <p className="text-lg font-medium">Credits History</p>
          {charges?.length <= 0 ? (
            <div className="flex flex-col items-center justify-center w-full h-[50vh] dark:bg-darkLightBg rounded-lg font-pro">
              <img
                src="/illustrations/invoice.svg"
                alt="Questions"
                className="w-[100px] h-[100px] mx-auto mt-10"
              />
              <h1 className="mt-[11px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
                Credits
              </h1>
              <p className="mt-2 text-[#888888] dark:text-white text-center font-pro">
                Your credits history will appear here!
              </p>
            </div>
          ) : (
            <div className="rounded-xl overflow-hidden mt-3">
              <Table>
                <TableHeader>
                  <TableRow className="text-[#888] font-medium font-pro">
                    <TableHead>Date</TableHead>
                    <TableHead>Amount</TableHead>
                    <TableHead>Credits</TableHead>
                    <TableHead>Type</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {chargesLoading ? (
                    <TableRow>
                      {new Array(4).fill("").map((_, i) => (
                        <TableCell key={i}>
                          <Skeleton
                            height={30}
                            baseColor={darkMode && "#262626"}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ) : (
                    <>
                      {charges?.length > 0
                        ? charges?.map((item) => (
                            <TableRow
                              className={`font-pro`}
                              key={item?.created_at}
                            >
                              <TableCell>
                                {item?.created_at &&
                                  dayFormatter(item?.created_at)}
                              </TableCell>
                              <TableCell>
                                {item?.amount &&
                                  billingStats?.currency &&
                                  Number(item?.amount)?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: billingStats?.currency,
                                    }
                                  )}
                              </TableCell>
                              <TableCell>{item?.credit}</TableCell>
                              <TableCell>{item?.charge_type}</TableCell>
                            </TableRow>
                          ))
                        : null}
                    </>
                  )}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
        <div className="mt-7">
          <p className="text-lg font-medium">Credit Requests</p>
          {creditRequests?.data?.length <= 0 ? (
            <div className="flex flex-col items-center justify-center w-full h-[50vh] dark:bg-darkLightBg rounded-lg font-pro">
              <img
                src="/illustrations/invoice.svg"
                alt="Questions"
                className="w-[100px] h-[100px] mx-auto mt-10"
              />
              <h1 className="mt-[11px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
                Credit Requests
              </h1>
              <p className="mt-2 text-[#888888] dark:text-white text-center font-pro">
                Your credit requests will appear here!
              </p>
            </div>
          ) : (
            <div className="rounded-xl overflow-hidden mt-3">
              <Table>
                <TableHeader>
                  <TableRow className="text-[#888] font-medium font-pro">
                    {[
                      "Credit",
                      "Requested Date",
                      "Updated Date",
                      "Status",
                      "Transaction No.",
                      "Screenshot",
                      "Message",
                    ]?.map((item) => (
                      <TableHead key={item}>{item}</TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {creditRequestsLoading ? (
                    <TableRow>
                      {new Array(7).fill("").map((_, i) => (
                        <TableCell key={i}>
                          <Skeleton
                            height={30}
                            baseColor={darkMode && "#262626"}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ) : (
                    <>
                      {creditRequests?.data?.length > 0
                        ? creditRequests?.data?.map((item) => (
                            <>
                              <TableRow className={`font-pro`} key={item?.id}>
                                <TableCell>{item?.credit}</TableCell>
                                <TableCell>
                                  {item?.created_at &&
                                    dayFormatter(item?.created_at)}
                                </TableCell>
                                <TableCell>
                                  {item?.updated_at &&
                                    dayFormatter(item?.updated_at)}
                                </TableCell>
                                <TableCell>
                                  {item?.status === "approved" && (
                                    <div className="w-[90px] h-6 rounded-[7px] bg-[#affebf] text-[#014b40] text-xs text-center flex justify-center items-center capitalize">
                                      {item?.status}
                                    </div>
                                  )}
                                  {item?.status === "rejected" && (
                                    <div className="w-[90px] h-6 rounded-[7px] text-[#8e0b21] bg-[#fed1d7] text-xs text-center flex justify-center items-center capitalize">
                                      {item?.status}
                                    </div>
                                  )}
                                  {item?.status === "pending" && (
                                    <div className="w-[90px] h-6 rounded-[7px] bg-[#ffd6a4] text-[#5e4200] text-xs text-center flex justify-center items-center capitalize">
                                      {item?.status}
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {item?.transaction_number || "-"}
                                </TableCell>
                                <TableCell>
                                  {item?.screenshot ? (
                                    <img
                                      className="w-6 h-6 rounded-md lg:mr-[9px] mr-[3px] cursor-pointer"
                                      src={item?.screenshot}
                                      alt=""
                                      referrerPolicy="no-referrer"
                                      onClick={() =>
                                        setIsOpenScreenshot(item?.id)
                                      }
                                    />
                                  ) : (
                                    "-"
                                  )}
                                </TableCell>
                                <TableCell>{item?.message || "-"}</TableCell>
                              </TableRow>
                              {isOpenScreenshot === item?.id && (
                                <ScreenshotModal
                                  isOpenModal={isOpenScreenshot === item?.id}
                                  setIsOpenModal={setIsOpenScreenshot}
                                  img={item?.screenshot}
                                />
                              )}
                            </>
                          ))
                        : null}
                    </>
                  )}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      </div>
      <CreditRequestModal
        isOpenModal={isOpenCreditReq}
        setIsOpenModal={setIsOpenCreditReq}
      />
    </Layout>
  );
};

export default SubUserBilling;
