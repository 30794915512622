import React, { useEffect, useState } from "react";
import Layout from "../component/Layout";
import { useInView } from "react-intersection-observer";
import { useGetEntityReviews } from "../../../query/useReview";
import Skeleton from "react-loading-skeleton";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { LoaderIcon, Sparkles } from "lucide-react";
import { ReviewsIllustration } from "../../onboarding/components/illustrations/Reviews";
import { useDarkMode } from "../../../context/DarkModeProvider";
import ReviewCardAutoReply from "./components/ReviewCardAutoReply";
import {
  useDeleteGmbReviewReply,
  useGetAutoReplyToggle,
  useGetGmbReviews,
  usePostAutoReplyToggle,
  usePostGenerateAiReply,
  usePutEditGmbReviewReply,
} from "../../../query/useAuthentication";
import { dayFormatter } from "../../../utils/formatter";
import { useQueryClient } from "@tanstack/react-query";
import { Textarea } from "../../../components/ui/textarea";
import { Button } from "../../../components/ui/button";
import { toast } from "sonner";
import { LogoLoading } from "../../../components/loading/loading";
import { DeleteModal } from "../component/modal";

const wordsToNumber = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
};

const AutoReply = () => {
  // const { inView, ref } = useInView();
  const { darkMode } = useDarkMode();

  const queryClient = useQueryClient();
  const [filter, setFilter] = useState(false);
  const [reviewReply, setReviewReply] = useState("");
  const [originalReviewReply, setOriginalReviewReply] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const { data: gmbReviews, isLoading: gmbReviewsLoading } = useGetGmbReviews();

  const { mutate: editReviewReply, isLoading: editReviewReplyLoading } =
    usePutEditGmbReviewReply();

  const { mutate: deleteReviewReply, isLoading: deleteReviewReplyLoading } =
    useDeleteGmbReviewReply();

  const { refetch: autoReplyRefetch } = useGetAutoReplyToggle({
    onSuccess: (res) => {
      setFilter(res.auto_reply);
    },
  });

  const { mutate: autoReplyToggle, isLoading: autoReplyToggleLoading } =
    usePostAutoReplyToggle();

  const { mutate: aiReply, isLoading: aiReplyLoading } =
    usePostGenerateAiReply();

  const handleAutoReplyToggle = () => {
    autoReplyToggle(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          autoReplyRefetch();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleEditReviewReply = () => {
    editReviewReply(
      {
        review_id: selectedReview?.review_id,
        comment: reviewReply,
      },
      {
        onSuccess: (res) => {
          toast.success("Your reply has been posted");
          queryClient.invalidateQueries(["getGmbReviews"]);
          setIsEditable(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleDeleteReviewReply = () => {
    deleteReviewReply(
      {
        review_id: selectedReview?.review_id,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          queryClient.invalidateQueries(["getGmbReviews"]);
          setIsOpenDeleteModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleAiReply = () => {
    aiReply(
      {
        review_id: selectedReview?.review_id,
      },
      {
        onSuccess: (res) => {
          setIsEditable(true);
          setReviewReply(res.ai_reply);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };
  // const entityId =
  //   localStorage.getItem("entityUuid") && localStorage.getItem("entityUuid");
  // const {
  //   data: reviews,
  //   hasNextPage,
  //   fetchNextPage,
  //   isLoading: isReviewsLoading,
  // } = useGetEntityReviews({
  //   entityId: entityId,
  //   limit: 9,
  //   platforms: [].join(","),
  //   is_posted_ai_review: true,
  // });
  // const flattenedReviews = reviews?.pages?.flatMap((page) => page?.results);
  // useEffect(() => {
  //   if (hasNextPage && inView) {
  //     fetchNextPage();
  //   }
  // }, [inView]);

  useEffect(() => {
    if (selectedReview) {
      setOriginalReviewReply(selectedReview?.review_reply);
      setReviewReply(selectedReview?.review_reply);
    }
  }, [selectedReview]);

  useEffect(() => {
    if (gmbReviews) {
      setSelectedReview(gmbReviews?.[0]);
    }
  }, [gmbReviews]);

  if (aiReplyLoading) {
    return <LogoLoading />;
  }

  return (
    <Layout title={"Responder AI Agent"}>
      <div className="w-full dark:bg-darkLightBg rounded-[16px] py-5 md:py-[31px] min-h-[calc(100vh-140px)] mt-5 my-5 px-5 lg:px-[30.2px]">
        <div className="flex flex-col lg:flex-row gap-5">
          <div className="w-full lg:w-2/5">
            <div className="overflow-y-auto scrollbar-thin max-h-[63vh]">
              {gmbReviewsLoading ? (
                <div className="grid grid-cols-1 gap-5 mt-5">
                  {new Array(3).fill("").map((_, i) => (
                    <div
                      className="flex rounded-[12px] p-5 bg-[#FFF] dark:bg-darkBg gap-y-5"
                      key={i}
                    >
                      <Skeleton
                        circle
                        className="w-[30px] h-[30px]"
                        baseColor={darkMode && "#262626"}
                      />
                    </div>
                  ))}
                </div>
              ) : gmbReviews?.length > 0 ? (
                <>
                  <div className="flex flex-col gap-3 overflow-y-auto pr-1 landscape:max-h-[80vh] portrait:max-h-[40vh]">
                    {gmbReviews?.map((item, i) => (
                      <div
                        key={item?.review_id}
                        className={`flex justify-between items-center p-5 cursor-pointer   rounded-[12px]  ${selectedReview?.review_id === item?.review_id ? "bg-newPrimary dark:bg-newPrimary" : "bg-white dark:bg-darkBg"}`}
                        onClick={() => setSelectedReview(item)}
                      >
                        <div>
                          <div className="flex gap-4 items-center">
                            <img
                              src={item?.profile_photo_url || "/new-logo.svg"}
                              alt=""
                              className="w-[30px] h-[30px]"
                              referrerPolicy="no-referrer"
                            />
                            <div>
                              <h1
                                className={`font-pro text-base font-medium mb-1 dark:text-white ${selectedReview?.review_id === item?.review_id ? "text-white" : "text-black"}`}
                              >
                                {item?.display_name || "MagicReview"}
                              </h1>
                              <p
                                className={` text-[13px] font-pro ${selectedReview?.review_id === item?.review_id ? "text-white" : "text-[#777]"}`}
                              >
                                {item?.update_time &&
                                  dayFormatter(item?.update_time)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-[3px]">
                          {[...Array(5)].map((_, index) => (
                            <svg key={index} width="20" height="20" fill="none">
                              <path
                                fill={
                                  index < wordsToNumber[item?.star_rating]
                                    ? "#F9C33C"
                                    : "#DDD"
                                }
                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 0 0 .95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 0 0-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 0 0-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 0 0-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 0 0 .951-.69l1.07-3.292-.001-.001Z"
                              />
                            </svg>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* {hasNextPage && (
                    <div ref={ref} className="text-center mt-10">
                      <LoaderIcon type="cylon" color="#7190FF" />
                    </div>
                  )} */}
                </>
              ) : (
                <ReviewsIllustration />
              )}
            </div>
          </div>
          <div className="w-full lg:w-3/5">
            <div
              className={`flex bg-white dark:bg-darkLightBg w-fit font-pro font-medium text-sm rounded-[10px] text-[#4d4d4d] dark:text-white border mb-5 ${autoReplyToggleLoading && "cursor-progress"}`}
            >
              <button
                className={` ${filter === false ? "bg-newPrimary text-white rouned-[10px] px-5 rounded-[10px] relative z-10 " : "px-2 py-1.5"}`}
                onClick={handleAutoReplyToggle}
              >
                Draft Mode
              </button>
              <button
                className={`${filter === true ? "bg-newPrimary text-white rouned-[10px] px-5 rounded-[10px] relative z-10 " : "px-2 py-1.5"}`}
                onClick={handleAutoReplyToggle}
              >
                Auto Reply
              </button>
            </div>
            <div className="dark:bg-darkBg duration-500 bg-white dark:border-none rounded-lg shadow-md flex justify-center items-center cursor-pointer p-5 lg:p-9 ">
              <div className="flex flex-col gap-y-5 bg-white dark:bg-darkBg w-full">
                <div className="flex flex-col gap-[35px]">
                  <div className="flex items-center justify-between flex-wrap gap-5">
                    <div className="flex gap-4 items-center">
                      <img
                        src={
                          selectedReview?.profile_photo_url || "/new-logo.svg"
                        }
                        alt=""
                        className="w-[50px] h-[50px]"
                        referrerPolicy="no-referrer"
                      />
                      <div>
                        <h1 className="font-pro text-base font-medium mb-1">
                          {selectedReview?.display_name || "MagicReview"}
                        </h1>
                        <p className="text-[#777] text-[13px] font-pro ">
                          {selectedReview?.update_time &&
                            dayFormatter(selectedReview?.update_time)}
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-[3px]">
                      {[...Array(5)].map((_, index) => (
                        <svg key={index} width="20" height="20" fill="none">
                          <path
                            fill={
                              index < wordsToNumber[selectedReview?.star_rating]
                                ? "#F9C33C"
                                : "#DDD"
                            }
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 0 0 .95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 0 0-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 0 0-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 0 0-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 0 0 .951-.69l1.07-3.292-.001-.001Z"
                          />
                        </svg>
                      ))}
                    </div>
                  </div>
                </div>

                <p className="text-[#777] dark:text-white text-sm font-pro mt-[10px]">
                  {selectedReview?.comment}
                </p>

                {selectedReview?.review_reply ? (
                  <div className="p-5 rounded-[12px] bg-[#f0f5ff] dark:bg-darkLightBg font-pro text-sm text-[#4a4a4a] dark:text-white">
                    {isEditable ? (
                      <>
                        <Textarea
                          value={reviewReply}
                          onChange={(e) => setReviewReply(e.target.value)}
                        />
                        <div className="flex gap-1 mt-3">
                          <Button
                            variant="secondary"
                            onClick={handleEditReviewReply}
                          >
                            Confirm
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={() => {
                              setIsEditable(false);
                              setReviewReply(originalReviewReply);
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        {reviewReply}
                        <div className="flex gap-1 mt-3">
                          {!filter && (
                            <>
                              <Button
                                variant="secondary"
                                onClick={handleAiReply}
                                className="gap-3"
                              >
                                <Sparkles /> Regenerate Reply
                              </Button>{" "}
                            </>
                          )}
                          <Button
                            variant="secondary"
                            onClick={() => setIsEditable(true)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={() => setIsOpenDeleteModal(true)}
                          >
                            Delete
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <>
                    {isEditable ? (
                      <>
                        <Textarea
                          value={reviewReply}
                          onChange={(e) => setReviewReply(e.target.value)}
                        />
                        <div className="flex justify-end gap-1.5 mt-3">
                          {!filter ? (
                            <>
                              <Button
                                variant="outline"
                                onClick={handleAiReply}
                                className="gap-3"
                              >
                                <Sparkles /> Regenerate Reply
                              </Button>{" "}
                              <Button onClick={handleEditReviewReply}>
                                Post
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                variant="secondary"
                                onClick={handleEditReviewReply}
                              >
                                Confirm
                              </Button>
                              <Button
                                variant="secondary"
                                onClick={() => {
                                  setIsEditable(false);
                                  setReviewReply(originalReviewReply);
                                }}
                              >
                                Cancel
                              </Button>
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="flex justify-end gap-3">
                        {!filter && (
                          <>
                            <Button
                              variant="outline"
                              onClick={handleAiReply}
                              className="gap-3"
                            >
                              <Sparkles /> Generate Reply
                            </Button>{" "}
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}

                <DeleteModal
                  openModal={isOpenDeleteModal}
                  setOpenModal={setIsOpenDeleteModal}
                  title={`Are you sure you want to delete the reply?`}
                  isLoading={deleteReviewReplyLoading}
                  handleDelete={handleDeleteReviewReply}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AutoReply;
